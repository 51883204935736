.content {
  margin: auto;
  max-width: 600px;
  height: 100vh;
  background-color: #d9f2d5;
  .search {
    padding-top: 40px;
  }
  .container {
    height: 100%;
    padding: 20px;
    .card {
      margin: 20px 0;
      display: flex;
      // height: 100%;
      background-color: #779e70;
      padding: 20px;
      box-shadow: 4px 8px 16px 0 darken(darken(rgb(190, 230, 180), 55%), 10%);
      .imgCard {
        width: 50%;
        background-color: #fff;
        // height: 100%;
        .avatar {
          padding: 10px;
          img {
            border-radius: 50%;
            width: 120px;
            height: 120px;
            object-fit: cover;
          }
        }
        .name {
          color: #309b12;
          font-weight: bold;
          font-size: 16px;
        }
        .mn {
          .ctn {
            margin: 8px;
            padding: 5px;
            border: 1px solid #c3e0c3;
            .moneyItem {
              font-size: 16px;
              font-weight: bold;
              color: #eb8585;
            }
          }
        }
        span {
          color: #689168;
          font-size: 14px;
        }
      }
      .moneyCard {
        width: 50%;
        // height: 100%;
        background-color: #cfecc7;
        .wr {
          border-bottom: 2px solid #fff;
          height: 100px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            font-size: 13px;
          }
          .moneyItem {
            font-size: 20px;
            font-weight: bold;
            color: #eb8585;
          }
        }
        .wr:last-child {
          border-bottom: unset;
        }
      }
    }
    .job {
      width: 100%;
      background-color: #cfecc7;
      .jobItem {
        ul {
          h3 {
            border: none;
            text-transform: uppercase;
            text-align: center;
            color: #18a418;
          }
          list-style-type: none;
          padding: 0;
          text-align: left;
          // margin-left: 35px;
          li {
            padding: 4px;
            border: 1px solid #dceddc;
            margin: 10px 0;
            margin-left: 14px;
            margin-right: 14px;
            background-color: #daefda;
            border-color: #20a520;
            span {
              margin-right: 10px;
            }
            span:last-child {
              color: #db8585;
              font-weight: bold;
            }
          }
          li:last-child {
            border: 4px solid #f27575;
          }
        }
      }
    }
  }
}
